<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div
      class="has-text-centered has-background-primary"
      style="margin-bottom: 20px"
    >
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">
        {{ pageTitle }}
      </h1>
    </div>
    <div style="max-width: 95%; margin: auto">
      <div
        id="body-content-area"
        style="overflow-x: hidden"
        :submit="validateAndSubmit"
      >
        <div v-if="isLoading">
          <Loading />
        </div>
        <div v-else>
          <div id="buttongroup" style="padding-top: 20px">
            <div
              class="field is-grouped"
              style="
                display: flex;
                justify-content: flex-end !important;
                gap: 1rem;
              "
            >
              <button
                :disabled="
                  !$hasPermissions(
                    clientSession,
                    ['ANNUAL_QUESTIONNAIRE'],
                    2
                  ) ||
                  (selectedCarrierHash === tracking.carrierHashOnLoad &&
                    selectedVendorHash === tracking.vendorHashOnLoad &&
                    rates === tracking.rates &&
                    carrierRatingSourceHash === tracking.carrierRatingSourceHashOnLoad
                  )
                "
                type="submit"
                class="button is-accent has-text-white"
                @click="validateAndSubmit"
              >
                Save
              </button>
              <a
                class="button is-light"
                v-on:click="
                  resetForm();
                  $router.go(-1);
                "
                >Cancel</a
              >
            </div>
          </div>
          <div id="pagelayout">
            <div class="columns" style="flex-wrap: wrap">
              <div class="column" style="min-width: 100%">
                <div v-if="errors && errors.length">
                  <div v-for="error in errors" :key="error" style="color: red">
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="column" style="min-width: 100%">
                <div class="columns">
                  <div class="column">
                    <div
                      class="block"
                      style="
                        padding: 0px 0px 7px 0px;
                        border-bottom: solid 1px lightgray;
                      "
                    >
                      <span class="has-text-weight-bold">Rates</span>
                    </div>
                    <div class="field" style="width: 100%">
                      <label class="label is-size-7">Annual Rate</label>
                      <div class="control">
                        <input
                          type="text"
                          class="input"
                          v-model="rates"
                          @keyup="RatesChanged($event)"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div
                      class="block"
                      style="
                        padding: 0px 0px 7px 0px;
                        border-bottom: solid 1px lightgray;
                      "
                    >
                      <span class="has-text-weight-bold">Report Info</span>
                    </div>
                    <div class="field" style="width: 100%">
                      <label class="label is-size-7">Carrier Rating Source</label>
                      <div class="control">
                        <input
                          type="text"
                          class="input"
                          v-model="carrierRatingSource"
                          @keyup="RatingSourceChanged($event)"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="block"
                  style="
                    padding: 0px 0px 7px 0px;
                    border-bottom: solid 1px lightgray;
                  "
                >
                  <span class="has-text-weight-bold"
                    >Carrier Due Diligence Inclusions</span
                  >
                </div>
                <div class="block">
                  <div class="level">
                    <div class="level-left" style="width: 45%">
                      <div class="field" style="width: 100%">
                        <label class="label is-size-7"
                          >Available Documents</label
                        >
                        <div class="control">
                          <div class="select is-multiple" style="width: 100%">
                            <select
                              name="carrierSelect"
                              ref="carrierSelectFrom"
                              v-model="input.selectedCarrierLeft"
                              multiple
                              :size="availableCarrier.length"
                              style="width: 100%; height: 250px !important"
                            >
                              <option
                                v-for="carrier in sort(availableCarrierList)"
                                :value="carrier.code"
                                :key="carrier.code"
                              >
                                {{ carrier.code }} - {{ carrier.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="level-center" style="width: 10%">
                      <div id="buttonblock" class="has-text-centered">
                        <div class="block">
                          <a
                            v-on:click="moveCarrierRight"
                            class="button is-accent is-outlined"
                          >
                            <i class="fas fa-caret-right fa-2x"></i>
                          </a>
                        </div>
                        <div class="block">
                          <a
                            v-on:click="moveCarrierLeft"
                            class="button is-accent is-outlined"
                          >
                            <i class="fas fa-caret-left fa-2x"></i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="level-right" style="width: 45%">
                      <div class="field" style="width: 100%">
                        <label class="label is-size-7"
                          >Include in Report<span style="color: red"
                            >*</span
                          ></label
                        >
                        <div class="control">
                          <div class="select is-multiple" style="width: 100%">
                            <select
                              ref="carrierSelectTo"
                              multiple
                              :size="selectedCarrier.length"
                              v-model="input.selectedCarrierRight"
                              style="width: 100%; height: 250px !important"
                            >
                              <option
                                v-if="selectedCarrier.length === 0"
                                disabled
                              >
                                Select a Carrier
                              </option>
                              <option
                                v-for="carrier in sort(selectedCarrier)"
                                :key="'sel' + carrier.code"
                                :value="carrier.code"
                              >
                                {{ carrier.code }} - {{ carrier.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="block"
                  style="
                    padding: 0px 0px 7px 0px;
                    border-bottom: solid 1px lightgray;
                  "
                >
                  <span class="has-text-weight-bold"
                    >Vendor Due Diligence Inclusions</span
                  >
                </div>
                <div class="block">
                  <div class="level">
                    <div class="level-left" style="width: 45%">
                      <div class="field" style="width: 100%">
                        <label class="label is-size-7"
                          >Available Documents</label
                        >
                        <div class="control">
                          <div class="select is-multiple" style="width: 100%">
                            <select
                              name="carrierSelect"
                              ref="carrierSelectFrom"
                              v-model="input.selectedVendorLeft"
                              multiple
                              :size="availableVendor.length"
                              style="width: 100%; height: 250px !important"
                            >
                              <option
                                v-for="carrier in sort(availableVendor)"
                                :value="carrier.code"
                                :key="carrier.code"
                              >
                                {{ carrier.code }} - {{ carrier.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="level-center" style="width: 10%">
                      <div id="buttonblock" class="has-text-centered">
                        <div class="block">
                          <a
                            v-on:click="moveVendorRight"
                            class="button is-accent is-outlined"
                          >
                            <i class="fas fa-caret-right fa-2x"></i>
                          </a>
                        </div>
                        <div class="block">
                          <a
                            v-on:click="moveVendorLeft"
                            class="button is-accent is-outlined"
                          >
                            <i class="fas fa-caret-left fa-2x"></i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="level-right" style="width: 45%">
                      <div class="field" style="width: 100%">
                        <label class="label is-size-7"
                          >Include in Report<span style="color: red"
                            >*</span
                          ></label
                        >
                        <div class="control">
                          <div class="select is-multiple" style="width: 100%">
                            <select
                              ref="carrierSelectTo"
                              multiple
                              :size="selectedVendor.length"
                              v-model="input.selectedVendorRight"
                              style="width: 100%; height: 250px !important"
                            >
                              <option
                                v-if="selectedVendor.length === 0"
                                disabled
                              >
                                Select a Carrier
                              </option>
                              <option
                                v-for="carrier in sort(selectedVendor)"
                                :key="'sel' + carrier.code"
                                :value="carrier.code"
                              >
                                {{ carrier.code }} - {{ carrier.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './../Loading'
import { mapState } from 'vuex'
import { activeSite } from '../../vuex-actions'
import * as crypto from 'crypto'
import * as _ from 'lodash'
import moment from 'moment'

let pageName = 'Annual {{ coliOrBoli }} Review - Configuration'

export default {
  components: {
    Loading
  },
  data () {
    return {
      errors: [],
      input: {
        selectedCarrierLeft: [],
        selectedCarrierRight: [],
        selectedVendorLeft: [],
        selectedVendorRight: [],
        rates: '2.5'
      },
      rates: 2.5,
      CarrierRatingSource: '',
      tracking: {
        carrierHashOnLoad: '',
        vendorHashOnLoad: '',
        carrierRatingSourceHashOnLoad: '',
        rates: '2.5'
      },
      carrierRatingSourceHash: '',
      selectedCarrier: [],
      selectedVendor: [],
      availableCarrier: [],
      availableCarrierList: [],
      availableVendor: [],
      availableVendorList: [],
      isLoading: true
    }
  },
  watch: {},
  computed: {
    ...mapState([activeSite, 'clientSession']),
    coliOrBoli () {
      return this.clientSession.siteId === 1 ? 'BOLI' : 'COLI'
    },
    pageTitle () {
      return (
        pageName.replaceAll('{{ coliOrBoli }}', this.coliOrBoli)
      )
    },
    selectedCarrierHash () {
      return crypto
        .createHash('sha256')
        .update(JSON.stringify(this.selectedCarrier))
        .digest('hex')
    },
    selectedVendorHash () {
      return crypto
        .createHash('sha256')
        .update(JSON.stringify(this.selectedVendor))
        .digest('hex')
    }
  },
  beforeMount () {
    this.getAnnualBOLIReviewConfiguration()
  },
  methods: {
    RatesChanged (e) {
      let val = e.target.value
      if (val && typeof val === 'string') {
        val = val.replace(/[^\d.]/g, '').replace(/(\.\d{2})\d+/, '$1')
        this.rates = val + '%'
      }
    },
    RatingSourceChanged (e) {
      let val = e.target.value
      this.carrierRatingSourceHash = crypto
        .createHash('sha256')
        .update(JSON.stringify(val))
        .digest('hex')
    },
    sort (array) {
      let unique = _.uniqBy(array, 'code')
      return _.sortBy(unique, 'name')
    },
    moveCarrierRight () {
      const moving = this.input.selectedCarrierLeft

      if (moving.length > 0) {
        moving.forEach((x) => {
          let carrier = this.availableCarrierList.find((y) => y.code === x)

          this.availableCarrierList = this.availableCarrierList.filter(
            (y) => y.code !== x
          )
          this.availableCarrier = this.availableCarrier.filter(
            (y) => y.code !== x
          )

          this.selectedCarrier.push(carrier)
          this.input.selectedCarrierLeft = []
        })
      }
    },
    moveCarrierLeft () {
      const moving = this.input.selectedCarrierRight

      if (moving.length > 0) {
        moving.forEach((x) => {
          let carrier = this.selectedCarrier.find((y) => y.code === x)

          this.selectedCarrier = this.selectedCarrier.filter(
            (y) => y.code !== x
          )
          this.availableCarrier.push(carrier)
          this.availableCarrierList.push(carrier)
          this.input.selectedCarrierRight = []
        })
      }
    },
    moveVendorRight () {
      const moving = this.input.selectedVendorLeft

      if (moving.length > 0) {
        moving.forEach((x) => {
          let vendor = this.availableVendorList.find((y) => y.code === x)

          this.availableVendorList = this.availableVendorList.filter(
            (y) => y.code !== x
          )
          this.availableVendor = this.availableVendor.filter(
            (y) => y.code !== x
          )

          this.selectedVendor.push(vendor)
          this.input.selectedVendorLeft = []
        })
      }

      // force a re-render
      this.$forceUpdate()
    },
    moveVendorLeft () {
      const moving = this.input.selectedVendorRight

      if (moving.length > 0) {
        moving.forEach((x) => {
          let vendor = this.selectedVendor.find((y) => y.code === x)

          this.selectedVendor = this.selectedVendor.filter((y) => y.code !== x)
          this.availableVendor.push(vendor)
          this.availableVendorList.push(vendor)
          this.input.selectedVendorRight = []
        })
      }

      // force a re-render
      this.$forceUpdate()
    },
    getAnnualBOLIReviewConfiguration () {
      this.isLoading = true
      this.api().annualBOLIReview.getConfiguration(
        moment().format('YYYY-MM-DD 00:00:00'),
        (_error, _response) => {
          let resp = _response // JSON.parse(_response) // TODO - This will need to be decoded when we attach to the API.
          let status = parseInt(resp.status)

          if (status >= 200 && resp.status < 300) {
            this.input = resp.data
            this.rates = resp.data.rates + '%'
            this.carrierRatingSource = resp.data.carrierRatingSource !== null && resp.data.carrierRatingSource !== undefined ? resp.data.carrierRatingSource : 'Vital Signs'
            this.tracking.carrierRatingSourceHashOnLoad = crypto
              .createHash('sha256')
              .update(JSON.stringify(this.carrierRatingSource))
              .digest('hex')
            this.RatingSourceChanged({
              target: {
                value: this.carrierRatingSource
              }
            })
            this.tracking.rates = resp.data.rates + '%'
            this.availableCarrier = resp.data.carrier.available.filter(
              (x) => x.category === 'carrier'
            )
            this.availableVendor = resp.data.vendor.available.filter(
              (x) => x.category === 'vendor'
            )

            this.selectedCarrier = resp.data.carrier.inclusions
            this.selectedVendor = resp.data.vendor.inclusions

            this.tracking.carrierHashOnLoad = crypto
              .createHash('sha256')
              .update(JSON.stringify(this.selectedCarrier))
              .digest('hex')
            this.tracking.vendorHashOnLoad = crypto
              .createHash('sha256')
              .update(JSON.stringify(this.selectedVendor))
              .digest('hex')

            this.availableCarrierList = [...this.availableCarrier]
            this.availableVendorList = [...this.availableVendor]

            this.input.selectedCarrierLeft = []
            this.input.selectedCarrierRight = []
            this.input.selectedVendorLeft = []
            this.input.selectedVendorRight = []
          } else {
            this.error(resp.response.data)
          }

          this.isLoading = false
        }
      )
    },
    async validateAndSubmit (e) {
      this.isLoading = true

      if (this.errors.length) {
        this.errors = []
      }

      if (this.errors.length > 0) {
        return
      }

      e.stopPropagation()
      e.preventDefault()

      this.api().annualBOLIReview.SaveConfiguration(
        {
          year: moment(),
          carrier: this.selectedCarrier,
          vendor: this.selectedVendor,
          rates: this.rates.replace('%', ''),
          carrierRatingSource: this.carrierRatingSource
        },
        (_error, _response) => {
          setTimeout((_) => {
            this.isLoading = false
            this.successToast(
              'Annual BOLI Review Configuration Saved',
              null,
              true
            )
            this.getAnnualBOLIReviewConfiguration()
          }, 2000)
        }
      )
    }
  }
}
</script>

<style scoped>

#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
.grid-button {
  margin-bottom: 5px;
}
.grid-header-controls {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.k-grid td:first-child {
  color: transparent !important;
}

.k-grid th:first-child {
  color: transparent !important;
}

.grid-container {
  margin-bottom: 1rem;
}

.visible {
  visibility: hidden;
}

.bottom-bar {
  margin-top: 0rem;
  margin-bottom: 1rem;

  display: flex;
  justify-content: flex-end;
}
.k-checkbox:checked {
  background-color: #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-master-row > td:first-child {
  color: transparent !important;
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  vertical-align: center !important;
}

#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}

.subheading {
  font-size: 1em;
  padding: 30px 0 10px 0;
}

div.flex-field {
  display: inline-block;
}

div.flex-field:not(:first-child) {
  padding-left: 20px;
}

.field {
  color: #4a4a4a;
}

.flex-column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.flex-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap !important;
  gap: 0.5rem;
}

.text-left {
  text-align: left !important;
}

.flex-container .flex-field {
  padding: 0px !important;
  margin: 0px !important;
}

.flex-center {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-left {
  justify-content: flex-start !important;
}

.flex-full {
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-full div {
  width: 100%;
}

.flex-full div div {
  width: 100%;
}

.flex-full div div input {
  width: 100%;
}

.field input,
.field textarea,
.flex-field input,
.column input,
.column textarea {
  font-size: 1em;
  background-color: white;
  border-color: #dbdbdb;
  border-radius: 4px;
  box-shadow: none;
  border-width: 1px;
  border-style: solid;
  min-height: 36px;
  color: #363636;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
}

/* Datatable CSS */
.v-datatable-light {
  display: inline-block;
  margin: 10px 0;
}
/* End Datatable CSS */

/* Invite Client CSS*/
.invite-client-wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  /* padding-right: 2.8rem; */
}

.invite-client-wrapper .control > .select {
  display: flex;
  height: 16rem;
}
/* End Invite Client CSS */
</style>

<style scoped>
.column {
  padding: 0.5rem !important;
  margin: 0rem !important;
}

.show-deleted {
  position: absolute;
  right: 0;
  top: calc(50% - 1rem);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.show-deleted button:not(.button),
.show-deleted a:not(.button) {
  background: transparent;
  border: 0px;
  cursor: pointer;
}

.show-deleted button:not(.button):hover,
.show-deleted a:not(.button):hover {
  text-decoration: underline;
}
</style>
